import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/dist/index.css'
import './style/common.css';
import '../src/style/font.css';
import router from './router'
import { createPinia } from 'pinia'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vue3SeamlessScroll from "vue3-seamless-scroll";
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(vue3SeamlessScroll)
// app.use(ElementPlus)
app.use(ElementPlus, {
  size: 'small',
  zIndex: 3000,
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router)
app.mount('#app')
