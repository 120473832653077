<template>
  <router-view></router-view>
</template>

<script setup>
window.scrollTo(0, 0)
</script>

<style>
a {
  color: rgb(51, 51, 51);
}
.el-table tr {
  height: 55px !important;
}
.el-carousel__button {
  height: 8px !important;
  width: 8px !important;
  border-radius: 100% !important;
}
* {
  font-family: "微软雅黑" !important;
  margin: 0;
  padding: 0;
}
</style>

