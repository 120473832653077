import {
    createRouter,
    createWebHistory
} from 'vue-router'


const routes = [{
    path: '/',
    redirect: '/index'
},
{
    path: '/index',
    meta: {title: '首页'},
    component: () => import('@/views/MyHome.vue')
},
{
    path: '/deposit',
    meta: {title: '保证金核交'},
    component: () => import('@/views/DepositPay.vue')
},
{
    path: '/depositPaySuccess',
    meta: {title: '保证金核交提交成功'},
    component: () => import('@/views/DepositPaySuccess.vue')
},
{
    path: '/newsList',
    meta: {title: '新闻列表页'},
    component: () => import('@/views/NewsList.vue')
},
{
    path: '/newsListDetail',
    meta: {title: '新闻详情页'},
    component: () => import('@/views/NewsListDetail.vue')
},
{
    path: '/login',
    meta: {title: '登录'},
    component: () => import('@/views/LoginPage.vue')
},
{
    path: '/businessRegistration',
    meta: {title: '注册'},
    component: () => import('@/views/BusinessRegistration.vue')
},
{
    path: '/businessRegistrationSuccess',
    meta: {title: '注册成功'},
    component: () => import('@/views/BusinessRegistrationSuccess.vue')
},
{
    path: '/forgotPassword',
    meta: {title: '忘记密码'},
    component: () => import('@/views/ForgotPassword.vue')
},
{
    path: '/personalCenter',
    meta: {title: '个人中心'},
    component: () => import('@/views/PersonalCenter.vue')
},
{
    path: '/applicationInsurance',
    meta: {title: '投保申请'},
    component: () => import('@/views/ApplicationInsurance.vue')
},
{
    path: '/applicationInsuranceSuccess',
    meta: {title: '投保申请成功'},
    component: () => import('@/views/ApplicationInsuranceSuccess.vue')
},
{
    path: '/coverageOptions',
    meta: {title: '投保选择'},
    component: () => import('@/views/CoverageOptions.vue')
},
{
    path: '/enterpriseInformationManagement',
    meta: {title: '企业信息管理'},
    component: () => import('@/views/personalCenterSubassembly/EnterpriseInformationManagement.vue')
},
{
    path: '/orderPay',
    meta: {title: '订单支付'},
    component: () => import('@/views/personalCenterSubassembly/orderPay.vue')
},
{
    path: '/invoiceApplication',
    meta: {title: '发票申请'},
    component: () => import('@/views/personalCenterSubassembly/InvoiceApplication.vue')
},
{
    path: '/myletterOfGuarantee',
    meta: {title: '我的保函'},
    component: () => import('@/views/personalCenterSubassembly/MyletterOfGuarantee.vue')
},
{
    path: '/postwarrantyInvestigation',
    meta: {title: '我的保函'},
    component: () => import('@/views/personalCenterSubassembly/PostwarrantyInvestigation.vue')
},
{
    path: '/cashdepositGuarantee',
    meta: {title: '现金缴存保函'},
    component: () => import('@/views/personalCenterSubassembly/CashDepositGuarantee.vue')
},
{
    path: '/invoiceApplicationDetail',
    meta: {title: '发票申请'},
    component: () => import('@/views/personalCenterSubassembly/InvoiceApplicationDetail.vue')
},
{
    path: '/enterpriseInformationManagement',
    meta: {title: '企业信息管理'},
    component: () => import('@/views/personalCenterSubassembly/EnterpriseInformationManagement.vue')
},
{
    path: '/ordersDetail',
    meta: {title: '订单详情'},
    component: () => import('@/views/OrdersDetail.vue')
},
{
    path: '/surrenderDetails',
    meta: {title: '退保详情'},
    component: () => import('@/views/SurrenderDetails.vue')
},
{
    path: '/perfectCash',
    meta: {title: '完善现金'},
    component: () => import('@/views/Perfectcash.vue')
},
{
    path: '/improveBank',
    meta: {title: '完善银行'},
    component: () => import('@/views/Improvebank.vue')
},
{
    path: '/aboutUs',
    meta: {title: '关于我们'},
    component: () => import('@/views/Aboutus.vue')
},
{
    path: '/partners',
    meta: {title: '合作伙伴'},
    component: () => import('@/views/Partners.vue')
}
]


const router = createRouter({
    routes,
    history: createWebHistory()
})


router.beforeEach((to, from, next) => {
    document.title = "怀集县农民工用工管理平台"
    document.documentElement.scrollTop = 0
    next();
    // console.log(to.path, 'to.path');
    // if (from.path === '/depositPaySuccess' || from.path === '/applicationInsuranceSuccess') {
    //     history.pushState(null, null, location.protocol + '//' + location.host + '/#' + to.path)
    // } else {
    //     next();
    // }
})

export default router